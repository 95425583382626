<template>
  <div class="link item" @click="() => $emit('click')">
    <i :class="icon" class="icon" v-if="icon"/>
    {{ text }}
    <slot v-if="label && label.visible !== false" name="label" v-bind:label="label">
      <div :class="label.class" class="floating ui circular label">
        <i :class="label.icon" class="icon" v-if="label.icon"/>
        {{ label.text }}
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'MenuButton',
    props: {
      icon: Object | String,
      label: Object,
      text: String,
    },
  }
</script>

<style scoped>
  .link.item > i.red.phone.icon {
    transform: rotate(-135deg);
  }

  .link.item > .ui.label {
    font-weight: bold;
  }

  .link.item > .ui.label > i.icon {
    margin: 0 !important;
  }
</style>
