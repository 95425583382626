<template>
  <div class="wrapper">
    <p v-translate>Thank you for attending the meeting. Please <a href="https://www.pitcher.com/">close</a> this tab/window.</p>
  </div>
</template>

<script>
  import useParticipantStatus from '../use/participant-status'
  import {onMounted} from "@vue/composition-api";

  export default {
    name: 'Exit',
    setup() {
      const {
        disconnect
      } = useParticipantStatus()

      onMounted(async () => {
        await disconnect()
      })
    }
  }
</script>

<style scoped>
  .wrapper {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>