<template>
  <div class="code">
    <div class="ui centered stackable grid container">
      <div class="header row">
        <div class="center aligned column">
          <div class="ui icon huge header">
            <i class="user chart thin icon"></i>
            <div class="content">
              {{ $gettext('Enter 9 digit code and join the meeting') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="seven wide computer ten wide mobile column">
          <ValidationObserver ref="form" slim v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="ui huge form">
              <ValidationProvider
                  :name="$gettext('Meeting Code')"
                  slim
                  v-slot="{ errors }"
                  vid="meeting">
                <div :class="{error: errors.length}" class="field">
                  <div :class="{loading: loading}" class="ui icon input">
                    <input
                        :disabled="loading"
                        autocomplete="off"
                        class="code-input"
                        id="meetingID"
                        type="text"
                        v-focus
                        v-mask="'###-###-###'"
                        v-model="meetingID">
                    <i class="icon"></i>
                  </div>
                  <p class="invalid-feedback">{{errors[0]}}</p>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <Logo :show-by="false"/>
  </div>
</template>

<script>
  import Logo from "../components/Logo";
  import router from '../router'
  import useDoc from '../use/doc'
  import {mask} from 'vue-the-mask'
  import {reactive, toRefs, watch} from '@vue/composition-api'
  import {ValidationObserver, ValidationProvider} from 'vee-validate'
  import useMeeting from '../use/meeting'

  export default {
    name: 'Code',
    components: {
      Logo,
      ValidationObserver,
      ValidationProvider
    },
    directives: {mask},
    setup() {
      const {
        exists,
        load,
        loading,
      } = useMeeting()

      const state = reactive({
        form: null,
        meetingID: ''
      })

      async function onSubmit() {
        await load(state.meetingID)

        if (!exists.value) {
          state.form.setErrors({meeting: [$gettext('Invalid meeting code')]})
          return
        }

        await router.push({
          name: 'precall',
          query: {
            meetingID: state.meetingID
          }
        })
      }

      watch(() => state.meetingID.length, async l => {
        if (l == 11) {
          await onSubmit()
        }
      })

      return {
        ...toRefs(state),
        loading,
        onSubmit
      }
    }
  }
</script>

<style lang="less" scoped>
  .code {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: fixed;
    width: 100%;

    .header.row {
      padding-bottom: 0;

      .ui.icon.header {
        margin-bottom: 0;
      }
    }

    .ui.icon.input > input.code-input {
      text-align: center;
      padding-right: 20px;
    }

    .invalid-feedback {
      color: #9f3a38;
      font-size: .9em;
      margin-top: .3em;
      text-align: center;
    }
  }
</style>
