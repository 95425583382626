<template>
  <div :id="id" :class="[...roles, browser, layout, type]" class="stream" ref="root">
    <div class="error ui active dimmer" v-if="error">
      <template v-if="id == 'camera'">
        <div class="ui basic active modal">
          <div class="content">
            <a :href="error.helpLink" class="ui red button" target="_blank">
              <i class="video slash icon"></i>
              {{$gettext('View help article')}}
            </a>
            <p>
              {{error.text}}
            </p>
          </div>
        </div>
      </template>
      <template v-if="id == 'screen'">
        <div class="ui basic active modal">
          <div class="content">
            <i class="yellow exclamation triangle"></i>
            {{error.text}}
          </div>
          <div class="actions">
            <a :href="error.helpLink" class="ui red large button" target="_blank">
              <i class="video slash icon"></i>
              {{$gettext('View help article')}}
            </a>
            <div @click="retry" class="ui large button" v-if="error.retry">
              <i class="redo icon"></i>
              {{$gettext('Retry')}}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import {onBeforeUnmount, onMounted, reactive, toRefs, watch} from '@vue/composition-api'

  const defaultPublisherProps = {
    camera: {
      facingMode: 'user',
      frameRate: 7,
      height: '100%',
      insertMode: 'append',
      publishAudio: true,
      publishVideo: true,
      resolution: '320x240',
      showControls: false,
      width: '100%',
    },
    screen: {
      frameRate: 7,
      height: '100%',
      insertMode: 'append',
      name: 'screen',
      publishAudio: false,
      resolution: '1280x720',
      showControls: false,
      videoSource: 'screen',
      width: '100%',
    },
  }
  const defaultSubscriberProps = {
    camera: {
      height: '100%',
      insertMode: 'append',
      showControls: true,
      width: '100%',
    },
    screen: {
      height: '100%',
      insertMode: 'append',
      showControls: false,
      width: '100%',
    },
  }

  const localErrors = {
    camera: {
      OT_CONSTRAINTS_NOT_SATISFIED: {
        helpLink: 'https://pconnect.live/browser',
        text: $gettext('Your browser is not supported. Please use a supported browser.'),
      },
      OT_USER_MEDIA_ACCESS_DENIED: {
        helpLink: 'https://pconnect.live/perm',
        text: $gettext('Your webcam access is blocked, please change your browser settings.'),
      },
    },
    screen: {
      OT_SCREEN_SHARING_NOT_SUPPORTED: {
        helpLink: 'https://pconnect.live/sharing',
        text: $gettext('Your browser is not supported. Please use a supported browser.'),
      },
      OT_USER_MEDIA_ACCESS_DENIED: {
        helpLink: 'https://pconnect.live/perm',
        retry: true,
        text: $gettext('Your screen access is blocked, please change your browser settings.'),
      },
    },
  }

  export default {
    name: 'stream',
    props: {
      id: {
        required: true,
        type: String,
      },
      layout: {
        default: 'float',
        validator: val => ['fullscreen', 'hangout-vertical'].includes(val),
      },
      name: {
        default: '',
        type: String,
      },
      publishAudio: {
        default: true,
        type: Boolean,
      },
      publishVideo: {
        default: false,
        type: Boolean,
      },
      roles: {
        default: [],
        type: Array,
        validator: val => val.filter(i => !['host', 'presenter'].includes(i)),
      },
      session: {
        required: true,
        type: Object,
      },
      stream: {
        type: Object,
      },
      type: {
        required: true,
        type: String,
      },
    },
    setup(props, {emit}) {
      const state = reactive({
        browser: parseBrowser(),
        publisher: null,
        root: null,
        subscriber: null,
        error: null,
      })

      function errorCallback(callback) {
        return (error, ...args) => {
          if (error) {
            const localError = localErrors[props.type][error.name]

            if (localError) {
              error.handled = true
              state.error = localError
            }

            emit('error', error)
          } else if (callback) {
            callback(...args)
          }
        }
      }

      function parseBrowser() {
        if (navigator.userAgent.toLowerCase().includes('safari')) {
          return 'safari'
        }
      }

      function publish() {
        const publisherProps = defaultPublisherProps[props.type]

        if (props.name) {
          publisherProps.name = props.name
        }

        if (props.type == 'camera') {
          publisherProps.publishAudio = props.publishAudio
          publisherProps.publishVideo = props.publishVideo
        }

        state.publisher = OT.initPublisher(state.root, publisherProps, errorCallback(() => {
          props.session.publish(state.publisher, errorCallback())
              .on('streamCreated', function (event) {
                emit('publisher-created', event)
              })
              .on('streamDestroyed', function (event) {
                emit('publisher-destroyed', event)
              })
        }))
      }

      function retry() {
        state.error = null

        if (props.stream) {
          subscribe()
        } else {
          publish()
        }
      }

      function subscribe() {
        state.subscriber = props.session.subscribe(
            props.stream,
            state.root,
            defaultSubscriberProps[props.type],
            errorCallback(),
        )
      }

      watch(props => [props.publishAudio, props.publishVideo], ([publishAudio, publishVideo]) => {
        if (state.publisher) {
          state.publisher.publishAudio(publishAudio)
          state.publisher.publishVideo(publishVideo)
        }
      })

      onMounted(() => {
        if (props.stream) {
          subscribe()
        } else {
          publish()
        }
      })

      onBeforeUnmount(() => {
        if (state.publisher) {
          props.session.unpublish(state.publisher)
        }
      })

      return {
        ...toRefs(state),
        retry,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .camera {
    height: 135px;
    margin-bottom: 10px;
    position: relative;
    width: 240px;

    &:last-child {
      margin-bottom: 0;
    }

    &.fullscreen {
      display: none;
    }

    .error .ui.modal {
      max-width: 100%;
      text-align: center;
      font-size: 1.2em;

      .ui.button {
        width: 100%;
        margin-bottom: .5em;
      }
    }
  }

  .screen {
    bottom: 65px;
    left: 260px;
    position: fixed;
    right: 10px;
    top: 10px;

    &.fullscreen {
      left: 10px;
    }

    .error .ui.modal {
      font-size: 2em;
      text-align: center;
      font-weight: 300;

      .actions {
        text-align: center;

        .ui.button {
          width: 200px;
        }
      }
    }
  }

  .test {
    background-color: chartreuse;

    &.host {
      background-color: aquamarine;
    }
  }
</style>

<style>
  .OT_publisher,
  .OT_subscriber {
    box-shadow: 0 2px 4px 0 #222222;
  }

  .error.ui.dimmer,
  .OT_publisher,
  .OT_subscriber,
  .safari .OT_video-loading,
  .safari video {
    border-radius: 12px;
  }

  .safari .OT_bar {
    border-radius: 12px 12px 0 0;
  }
</style>
