<template>
  <div class="logo">
    <p v-show="showBy">by</p>
    <a href="https://www.pitcher.com" target="_blank">
      <img src="/assets/pitcher-logo-COLOR-R-300.png" alt="Pitcher AG">
    </a>
  </div>
</template>

<script>
  export default {
    name: "Logo",
    props: {
      showBy: {
        default: true,
        type: Boolean
      }
    }
  }
</script>

<style scoped>
  .logo {
    bottom: 35px;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
  }

  img {
    max-width: 150px;
  }

  p {
    margin-bottom: .5em;
  }
  
  @media(max-height: 475px) {
    .logo {
      display: none;
    }
  }
</style>