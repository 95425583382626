import firebase from 'firebase/app'
import 'firebase/database'
import {reactive, toRefs} from '@vue/composition-api'

const state = reactive({
  cancel: null,
  participantKey: null,
  participantStatusDatabaseRef: null
})

export default () => {
  const database = firebase.database()

  if (!state.participantKey) {
    state.participantKey = database.ref('participants').push().key
    state.participantStatusDatabaseRef = database.ref(`participants/${state.participantKey}`)
  }

  function connect(defaultValue) {
    if (!state.cancel) {
      state.cancel = firebase.database().ref('.info/connected').on('value', function (snapshot) {
        if (!snapshot || snapshot.val() == false) {
          return
        }

        state.participantStatusDatabaseRef.onDisconnect().remove().then(function () {
          state.participantStatusDatabaseRef.set({
            ...defaultValue,
            dateCreated: firebase.database.ServerValue.TIMESTAMP
          })
        })
      })
    }
  }

  async function disconnect() {
    if (state.cancel) {
      await state.participantStatusDatabaseRef.remove()

      state.cancel()
      state.cancel = null
    }
  }

  return {
    ...toRefs(state),
    connect,
    disconnect
  }
}