import {ref} from "@vue/composition-api"

const names =  [
  'Richard Hendricks',
  "Nelson 'Big Head' Bighetti",
  'Bertram Gilfoyle',
  'Dinesh Chugtai',
  'Monica Hall',
  "Donald 'Jared' Dunn",
  'Gavin Belson',
  'Jian Yang'
]

export default () => {
  const name = ref(generate())

  function generate() {
    return names[Math.floor(Math.random() * names.length)]
  }

  function update() {
    name.value = generate();
  }

  return {
    generate,
    name,
    update,
  }
}