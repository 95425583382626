<template>
  <div id="app" v-if="loaded">
    <router-view/>
  </div>
</template>

<script>
  import FastClick from 'fastclick/lib/fastclick'
  import {onMounted, reactive, toRefs} from '@vue/composition-api'
  import {useBrowserLanguage, useI18nStore} from '@pitcher/vue-sdk'

  export default {
    name: 'app',
    setup() {
      const i18n = useI18nStore()

      const state = reactive({
        loaded: false,
      })

      onMounted(() => {
        FastClick.attach(document.body)

        try {
          const {getAvailableBrowserLanguage} = useBrowserLanguage()
          const lang = getAvailableBrowserLanguage(Object.keys(i18n.state.availableLanguages))

          if (lang == 'en') {
            i18n.setLanguage(lang, {dir: 'lang'})
          }
        } catch (e) {
          console.warn('failed to set browser languge')
        }

        state.loaded = true
      })

      return {
        ...toRefs(state),
      }
    },
  }
</script>

<style>
@font-face {
  font-family: 'thin-icons';
  src: url("./assets/themes/default/assets/fonts/thin-icons.eot");
  src: url("./assets/themes/default/assets/fonts/thin-icons.eot?#iefix") format('embedded-opentype'), url("./assets/themes/default/assets/fonts/thin-icons.woff2") format('woff2'), url("./assets/themes/default/assets/fonts/thin-icons.woff") format('woff'), url("./assets/themes/default/assets/fonts/thin-icons.ttf") format('truetype'), url("./assets/themes/default/assets/fonts/thin-icons.svg#icons") format('svg');
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}

i.icon.thin {
  font-family: 'thin-icons';
}

i.icon.user.chart.thin:before {
  content: "\f6a3";
}
</style>
