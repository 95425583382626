import 'core-js/stable'
import 'cross-fetch/polyfill';
import 'regenerator-runtime/runtime'

// requires polyfills
import './plugins/composition-api'
import './plugins/firebase'
import './plugins/focus'
import './plugins/i18n'
import './plugins/portal-vue'
import './plugins/semantic'
import './plugins/sentry'
import App from './App.vue'
import router from './router'
import Vue from 'vue'

const app = new Vue({
  router,
  render: h => h(App),
})

app.$mount('#app')
