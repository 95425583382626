<template>
  <SuiModal :open="open" v-bind="attrs" v-on="$listeners">
    <div class="ui header" v-bind="header.attrs" v-if="header">
      <i class="icon" v-bind="header.icon.attrs" v-if="header.icon"/>
      <div class="content">
        {{header.text}}
      </div>
    </div>
    <div class="content" v-bind="content.attrs" v-if="content">
      <p>{{content.text}}</p>
    </div>
    <div class="actions" v-if="actions">
      <div v-for="action in actions">
        <div class="ui button" v-bind="action.attrs" v-on="action.listeners">
          <i class="icon" v-bind="action.icon.attrs" v-if="action.icon"/>
          {{ action.text }}
        </div>
      </div>
    </div>
  </SuiModal>
</template>

<script>
  export default {
    name: 'BasicModal',
    model: {
      prop: 'open',
      event: 'changed',
    },
    props: {
      actions: {
        type: Array|Object
      },
      attrs: {
        type: Object,
      },
      content: {
        type: Object,
      },
      header: {
        type: Object,
      },
      open: {
        type: Boolean
      }
    }
  }
</script>

<style scoped>

</style>
