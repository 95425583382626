<template>
  <MenuButton v-bind="$attrs" @click="() => $emit('click')">
    <template #label="{ label }">
      <div :class="label.class" class="ui right floated label">
        <i :class="label.icon" class="icon" v-if="label.icon" style="margin-right: 0 !important;"/>
        {{ label.text }}
      </div>
    </template>
  </MenuButton>
</template>

<script>
  import MenuButton from './MenuButton'
  export default {
    name: 'MenuDropdownButton',
    components: {MenuButton},
  }
</script>

<style scoped>
  .ui.label  {
    float: right !important;
    margin-top: -2px !important;
  }
</style>
