import {computed, reactive, toRefs} from '@vue/composition-api'

const state = reactive({
  messages: [],
  unreadMessages: computed(() => state.messages.filter(m => !m.read)),
})

export function useChat() {
  function addMessage(message, read) {
    state.messages.push({message, read})
  }

  function makAllAsRead() {
    for (const message of state.messages) {
      message.read = true
    }
  }

  return {
    ...toRefs(state),
    addMessage,
    makAllAsRead,
  }
}
