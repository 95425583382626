<template>
  <div class="stream-view" ref="targetElement" v-bind="$attrs">
    <div class="error ui active dimmer" v-if="error">
      <template v-if="id == 'camera-publisher'">
        <div class="ui basic active modal">
          <div class="content">
            <a :href="error.helpLink" class="ui red button" target="_blank">
              <i class="video slash icon"></i>
              {{$gettext('View help article')}}
            </a>
            <p>
              {{error.text}}
            </p>
          </div>
        </div>
      </template>
      <template v-if="id == 'screen-publisher'">
        <div class="ui basic active modal">
          <div class="content">
            <i class="yellow exclamation triangle"></i>
            {{error.text}}
          </div>
          <div class="actions">
            <a :href="error.helpLink" class="ui red large button" target="_blank">
              <i class="video slash icon"></i>
              {{$gettext('View help article')}}
            </a>
            <div @click="retryPublishScreen" class="ui large button" v-if="error.retry">
              <i class="redo icon"></i>
              {{$gettext('Retry')}}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import {onBeforeUnmount, onMounted, ref} from '@vue/composition-api'
  import useOpenTokHelper from '../use/opentok-helper'

  const publishOptions = {
    'camera-publisher': {
      facingMode: 'user',
      frameRate: 7,
      height: '100%',
      insertMode: 'append',
      publishAudio: true,
      publishVideo: false,
      resolution: '320x240',
      showControls: false,
      width: '100%',
    },
    'screen-publisher': {
      frameRate: 7,
      height: '100%',
      insertMode: 'append',
      name: 'screen',
      publishAudio: false,
      resolution: '1280x720',
      showControls: false,
      videoSource: 'screen',
      width: '100%',
    }
  }

  const streamViewErrors = {
    'camera-publisher': {
      OT_CONSTRAINTS_NOT_SATISFIED: {
        helpLink: 'https://pconnect.live/browser',
        text: $gettext('Your browser is not supported. Please use a supported browser.'),
      },
      OT_USER_MEDIA_ACCESS_DENIED: {
        helpLink: 'https://pconnect.live/perm',
        text: $gettext('Your webcam access is blocked, please change your browser settings.'),
      },
    },
    'screen-publisher': {
      OT_SCREEN_SHARING_NOT_SUPPORTED: {
        helpLink: 'https://pconnect.live/browser',
        text: $gettext('Your browser is not supported. Please use a supported browser.'),
      },
      OT_USER_MEDIA_ACCESS_DENIED: {
        helpLink: 'https://pconnect.live/perm',
        retry: true,
        text: $gettext('Your screen access is blocked, please change your browser settings.'),
      },
    },
  }

  export default {
    name: 'StreamView',
    props: {
      id: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      const {
        publishCamera,
        publishScreen,
        streams,
        subscribe,
        unpublishCamera,
        unpublishScreen,
        unsubscribe,
      } = useOpenTokHelper()

      const error = ref(null)
      const stream = streams.value.find(s => s.streamId == props.id)
      const targetElement = ref(null)

      async function retryPublishScreen() {
        error.value = null

        try {
          await publishScreen(targetElement.value, publishOptions[props.id])
        } catch (e) {
          if (streamViewErrors[props.id] && streamViewErrors[props.id][e.name]) {
            error.value = streamViewErrors[props.id][e.name]
          }
        }
      }

      onMounted(async () => {
        try {
          if (props.id == 'camera-publisher') {
            await publishCamera(targetElement.value, publishOptions[props.id])
          } else if (props.id == 'screen-publisher') {
            await publishScreen(targetElement.value, publishOptions[props.id])
          } else {
            await subscribe(stream, targetElement.value, {
              height: '100%',
              insertMode: 'append',
              showControls: stream.videoType == 'camera',
              width: '100%',
            })
          }
        } catch (e) {
          if (streamViewErrors[props.id] && streamViewErrors[props.id][e.name]) {
            error.value = streamViewErrors[props.id][e.name]
          }
        }
      })

      onBeforeUnmount(() => {
        if (props.id == 'camera-publisher') {
          unpublishCamera()
        } else if (props.id == 'screen-publisher') {
          unpublishScreen()
        } else {
          unsubscribe(stream)
        }
      })

      return {
        error,
        retryPublishScreen,
        targetElement,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .stream-view {
    position: relative;

    /deep/ .OT_root {
      position: absolute;
    }
  }

  .error.dimmer {
    z-index: auto;
  }

  .camera-publisher .error .ui.modal {
    max-width: 100%;
    text-align: center;
    font-size: 1.2em;

    .ui.button {
      width: 100%;
      margin-bottom: .5em;
    }
  }

  .screen-publisher .error .ui.modal {
    font-size: 2em;
    text-align: center;
    font-weight: 300;

    .actions {
      text-align: center;

      .ui.button {
        width: 200px;
      }
    }
  }
</style>
