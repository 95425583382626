import firebase from 'firebase/app'
import 'firebase/database'

import {computed, reactive, toRefs} from '@vue/composition-api'

export default () => {
  const database = firebase.database()

  const state = reactive({
    loading: true,
    exists: false,
    val: null,
    docs: computed(() => {
      if (state.val) {
        const res = []

        for (const key in state.val) {
          if (state.val.hasOwnProperty(key)) {
            res.push({
              ...state.val[key],
              key
            })
          }
        }

        return res
      } else {
        return []
      }
    }),
    path: null
  })

  async function loadByPath(path, filter = {}) {
    let ref = database.ref(path)

    for (const key in filter) {
      ref = ref.orderByChild(key).equalTo(filter[key])
    }

    const snapshot = await ref.once('value')
    state.exists = snapshot.exists()
    state.loading = false
    state.path = path
    state.val = snapshot.val()

    return state.exists
  }

  return {
    ...toRefs(state),
    loadByPath
  }
}
