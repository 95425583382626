import firebase from 'firebase/app'
import 'firebase/functions'

export default () => {
  const functions = firebase.functions()

  return {
    createOpentokToken: functions.httpsCallable('createOpentokToken'),
    createOpentokNetworkTestCredentials: functions.httpsCallable('createOpentokNetworkTestCredentials')
  }
}