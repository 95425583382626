<template>
    <component class="control" :is="is" @click="onClick" :class="classObject">
        <i class="icon">
            <i :class="icon" class="icon" v-if="icon"/>
        </i>
        {{content}}
    </component>
</template>

<script>
    export default {
        name: "Control",
        props: {
            active: Boolean,
            content: String,
            disabled: Boolean,
            icon: String,
            link: Boolean
        },
        computed: {
            classObject() {
                return {
                    active: this.active,
                    disabled: this.disabled,
                    link: this.link
                }
            },
            is() {
                if (this.link) {
                    return 'a';
                } else {
                    return 'div';
                }
            }
        },
        methods: {
            onClick(e) {
                if (this.link) {
                    this.$emit('click', e);
                }
            }
        }
    }
</script>

<style scoped>

</style>