import {computed, ref} from '@vue/composition-api'
import useDoc from './doc'

export default function useRoles() {
  const {
    doc: authData,
    loadById: loadAuthData
  } = useDoc('authtokens')

  const roles = ref(['participant'])

  async function loadByAuthToken(authToken) {
    try {
      await loadAuthData(authToken)
    } catch (e) {
      console.error(e)
    }

    if (authData.value.roles) {
      roles.value = authData.value.roles
    }
  }

  return {
    loadByAuthToken,
    roles,
  }
}
