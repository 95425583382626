import {computed, reactive, toRefs, watchEffect} from '@vue/composition-api'

export default function useLayout() {
  const state = reactive({
    layouts: computed(() => ({
      cameras: state.mobile,
      fullscreen: true,
      hangout: !state.mobile,
    })),
    mobile: computed(() => state.window.width < 960),
    portrait: computed(() => state.window.height > state.window.width),
    selected: 'hangout',
    window: {
      height: window.innerHeight,
      width: window.innerWidth,
    }
  })

  function addEventListeners() {
    window.addEventListener('resize', onResize)
  }

  function onResize() {
    state.window.height = window.innerHeight
    state.window.width = window.innerWidth
  }

  function removeEventListeners() {
    window.removeEventListener('resize', onResize)
  }

  watchEffect(() => {
    if (!state.layouts[state.selected]) {
      state.selected = Object.keys(state.layouts).find(i => state.layouts[i])
    }
  })

  return {
    ...toRefs(state),
    addEventListeners,
    removeEventListeners,
  }
}
