<template>
  <div>
    <div class="centered">
      <h1 class="ui header">
        JOIN
      </h1>
      <div class="ui tiny blue sliding indeterminate progress">
        <div class="bar"></div>
      </div>
    </div>
    <Logo/>
    <BasicModal :open="!!modal" v-bind="modal" v-if="modal"/>
  </div>
</template>

<script>
  import * as Sentry from '@sentry/browser'
  import BasicModal from '../components/BasicModal'
  import Logo from '../components/Logo'
  import OT from '@opentok/client'
  import router from '../router'
  import useAuth from '../use/auth'
  import useMeeting from '../use/meeting'
  import {computed, onMounted, reactive, toRefs} from '@vue/composition-api'

  class InvalidMeetingError extends Error {
    constructor(message) {
      super(message)
      this.name = 'INVALID_MEETING'
    }
  }

  class NotSupportedError extends Error {
    constructor(message) {
      super(message)
      this.name = 'OT_NOT_SUPPOERTED'
    }
  }

  const modals = {
    INVALID_MEETING: {
      attrs: {
        basic: true,
        size: 'mini'
      },
      header: {
        text: $gettext('Unable to join this meeting')
      },
      content: {
        text: $gettext('This meeting ID is not valid. Please check and try again.')
      },
      actions: {
        ok: {
          attrs: {
            class: 'blue'
          },
          listeners: {
            click: () => window.open('/', '_self')
          },
          text: $gettext('OK')
        }
      }
    },
    OT_NOT_SUPPOERTED: {
      attrs: {
        basic: true,
        size: 'mini',
      },
      header: {
        attrs: {
          style: {
            fontSize: '2em',
            textAlign: 'center'
          }
        },
        text: $gettext('Incompatible Browser')
      },
      content: {
        attrs: {
          style: {
            fontSize: '1.5em',
            textAlign: 'center'
          }
        },
        text: $gettext('Your browser is not supported. Please use a supported browser.')
      },
      actions: {
        help: {
          attrs: {
            class: 'large fluid red'
          },
          icon: {
            attrs: {
              class: 'video slash'
            }
          },
          listeners: {
            click: () => window.open('https://pconnect.live/browser', '_blank'),
          },
          text: $gettext('View help article')
        }
      }
    },
    UNKNOWN_ERROR: {
      attrs: {
        basic: true,
      },
      header: {
        attrs: {
          style: {
            fontSize: '2em',
          }
        },
        text: $gettext('We\'re sorry, but we\'re unable to connect to our servers.')
      },
      content: {
        attrs: {
          style: {
            fontSize: '1.5em',
          }
        },
        text: $gettext('Please check your connection and try refreshing the page.')
      },
      actions: {
        help: {
          attrs: {
            class: 'large red'
          },
          icon: {
            attrs: {
              class: 'video slash'
            }
          },
          listener: () => window.open('https://pconnect.live/browser', '_blank'),
          text: $gettext('View help article')
        }
      }
    },
  }

  export default {
    name: 'Splashscreen',
    components: {BasicModal, Logo},
    props: {
      authToken: {
        type: String,
      },
      displayName: {
        type: String,
      },
      meetingID: {
        type: String,
      },
    },
    setup(props) {
      const {
        signInAnonymously,
      } = useAuth()

      const {
        exists,
        load,
      } = useMeeting()

      const state = reactive({
        error: null,
        modal: computed(() => {
          if (state.error) {
            if (modals[state.error.name]) {
              return modals[state.error.name]
            } else {
              return modals['UNKNOWN_ERROR']
            }
          }
        }),
      })

      onMounted(async () => {
        try {
          const hasRequirements = OT.checkSystemRequirements()

          if (!hasRequirements) {
            throw new NotSupportedError()
          }

          await signInAnonymously()

          if (props.meetingID) {
            await load(props.meetingID)

            if (!exists.value) {
              throw new InvalidMeetingError(`meeting ${props.meetingID} not found`)
            }

            await router.push({
              name: 'precall',
              query: props,
            })
          } else {
            await router.push({
              name: 'code',
            })
          }
        } catch (error) {
          state.error = error
          Sentry.captureException(error)
        }
      })

      return {
        ...toRefs(state),
      }
    },
  }
</script>

<style lang="less" scoped>
  .centered {
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);

    & > .ui.header {
      font-size: 96px;
    }
  }
</style>
