import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyCsrvA5XGvBXIImParD9JjW4VXuqAcxEA4',
  appId: '1:1062876731915:web:e10fe9f6ea3fc7565c850e',
  authDomain: 'pitcher-meetings.firebaseapp.com',
  databaseURL: 'https://pitcher-meetings.firebaseio.com',
  measurementId: 'G-BKFPNK76M1',
  messagingSenderId: '1062876731915',
  projectId: 'pitcher-meetings',
  storageBucket: 'pitcher-meetings.appspot.com',
}
firebase.initializeApp(firebaseConfig)

// utils
export const db = firebase.firestore()
export const rtdb = firebase.database()
export const auth = firebase.auth()
export const functions = firebase.functions()

// collections
export const meetingsCollection = db.collection('meetings')
export const participantsRef = rtdb.ref('participants')

// functions
export const createCustomToken = functions.httpsCallable('createCustomToken')
export const createMeeting = functions.httpsCallable('createMeeting_v2')
