<template>
  <div class="room" v-bind="room.attrs">
    <div class="background" v-bind="background.attrs"/>
    <img :src="logo" alt="Logo" class="logo"/>
    <div class="stream-views">
      <OTStreamView :key="streamView.id" v-bind="streamView" v-for="streamView of streamViews"/>
    </div>
    <a @click="buttons.chevron.listener" class="sidebar-button small inverted circular ui icon button"
       v-show="buttons.chevron.visible">
      <i :class="buttons.chevron.icon" class="icon"/>
      {{buttons.chevron.text}}
    </a>
    <h2 class="ui header" v-bind="header.attrs">
      <i class="users icon"/>
      <div class="content">
        {{meetingID}}
        <div class="sub header" v-bind="header.subHeader.attrs" v-if="header.subHeader.visible">
          {{header.subHeader.text}}
        </div>
      </div>
    </h2>
    <div class="ui bottom fixed secondary labeled icon menu" v-bind="menu.attrs">
      <MenuButton
          class="audio-button"
          v-bind="buttons.audio.attrs"
          v-if="buttons.audio.visible"
          @click="buttons.audio.listener"
      />
      <MenuButton
          class="video-button"
          v-bind="buttons.video.attrs"
          v-if="buttons.video.visible"
          @click="buttons.video.listener"
      />
      <div class="divider"/>
      <MenuButton
          class="participants-button"
          v-bind="buttons.participants.attrs"
          @click="buttons.participants.listener"
      />
      <MenuButton
          class="chat-button"
          v-bind="buttons.chat.attrs"
          v-if="buttons.chat.visible"
          @click="buttons.chat.listener"
      />
      <MenuButton
          class="screen-button"
          v-bind="buttons.screen.attrs"
          v-if="buttons.screen.visible"
          @click="buttons.screen.listener"
      />
      <div class="divider"/>
      <MenuButton
          class="leave-button"
          v-bind="buttons.leave.attrs"
          @click="buttons.leave.listener"
      />
      <MenuDropdownButton
          class="help-button"
          v-bind="buttons.help.attrs"
          @click="buttons.help.listener"
      />
      <SuiDropdown class="inverted link item" direction="upward" icon="ellipsis horizontal" pointing="bottom right">
        {{$gettext('More')}}
        <SuiDropdownMenu class="inverted">
          <MenuDropdownButton
              class="chat-button"
              v-bind="buttons.chat.attrs"
              v-if="buttons.chat.visible"
              @click="buttons.chat.listener"
          />
          <MenuDropdownButton
              class="screen-button"
              v-bind="buttons.screen.attrs"
              v-if="buttons.screen.visible"
              @click="buttons.screen.listener"
          />
          <MenuDropdownButton
              class="help-button"
              v-bind="buttons.help.attrs"
              @click="buttons.help.listener"
          />
          <MenuDropdownButton
              class="leave-button"
              v-bind="buttons.leave.attrs"
              @click="buttons.leave.listener"
          />
        </SuiDropdownMenu>
      </SuiDropdown>
    </div>
    <Portal>
      <ChatModal v-bind="modals.chat.attrs" v-model="modals.chat.visible" v-on="modals.chat.listeners"/>
      <ExitModal v-model="modals.exit.visible"/>
      <OTErrorModal :error="error"/>
    </Portal>
  </div>
</template>

<script>
  import ChatModal from '../components/ChatModal'
  import Control from '../components/Control'
  import ExitModal from '../components/ExitModal'
  import OTErrorModal from '../components/OTErrorModal'
  import OTStreamView from '../components/OTStreamView'
  import Stream from '../components/Stream'
  import useFunctions from '../use/functions'
  import useLayout from '../use/layout'
  import useMeeting from '../use/meeting'
  import useOpenTokHelper from '../use/opentok-helper'
  import useParticipantStatus from '../use/participant-status'
  import useRoles from '../use/roles'
  import {computed, onBeforeUnmount, onMounted, reactive, toRefs, watch} from '@vue/composition-api'
  import {convertHexToRgb, isColorLight} from '../colors'
  import MenuButton from '../components/MenuButton'
  import MenuDropdownButton from '../components/MenuDropdownButton'
  import {useChat} from '../use/chat'

  export default {
    name: 'Conference',
    components: {
      MenuDropdownButton,
      MenuButton,
      OTErrorModal,
      OTStreamView,
      ChatModal,
      Control,
      ExitModal,
      Stream,
    },
    props: {
      authToken: {
        type: String,
      },
      displayName: {
        default: '',
        type: String,
      },
      meetingID: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      const {
        createOpentokToken,
      } = useFunctions()

      const {
        addEventListeners: addLayoutEventListeners,
        layouts,
        mobile,
        portrait,
        removeEventListeners: removeLayoutEventListeners,
        selected: layout,
      } = useLayout()

      const {
        meeting,
        publishers: meetingPublishers,
        session,
      } = useMeeting()

      const {
        unreadMessages,
      } = useChat()

      const {
        connect,
        connected,
        connections,
        disconnect,
        enableCamera,
        enableScreen,
        error,
        publishers,
        setCameraName,
        streamViews,
        toggleCamera,
        toggleCameraAudio,
        toggleCameraVideo,
        toggleScreen,
      } = useOpenTokHelper()

      const {
        connect: connectStatus,
        participantKey,
      } = useParticipantStatus()

      const {
        loadByAuthToken: loadRoles,
        roles,
      } = useRoles()

      const state = reactive({
        background: {
          attrs: {
            style: {
              backgroundColor: meeting.value.uiColorBack,
            },
          },
        },
        buttons: computed(() => ({
          chevron: {
            icon: {
              chevron: true,
              left: layout.value == 'hangout',
              right: layout.value == 'fullscreen',
            },
            listener: onChevronClick,
            text:
              layout.value == 'fullscreen'
                ? $gettext('Show Cameras')
                : $gettext('Hide Cameras'),
            visible: layouts.value.hangout && state.user.active,
          },
          audio: {
            attrs: {
              class: {
                disabled: !!publishers.value.camera.error,
              },
              icon: {
                microphone: true,
                slash: !publishers.value.camera.audio,
              },
              label: {
                class: 'yellow',
                icon: 'exclamation',
                visible: !!publishers.value.camera.error,
              },
              text:
                publishers.value.camera.audio
                  ? $gettext('Mute')
                  : $gettext('Unmute'),
            },
            listener: onAudioButtonClick,
            visible: publishers.value.camera.enabled,
          },
          video: {
            attrs: {
              class: {
                disabled: !!publishers.value.camera.error,
              },
              icon: {
                slash: publishers.value.camera.video,
                video: true,
              },
              label: {
                class: 'yellow',
                icon: 'exclamation',
                visible: !!publishers.value.camera.error,
              },
              text:
                publishers.value.camera.video
                  ? $gettext('Stop Video')
                  : $gettext('Start Video'),
            },
            listener: onCameraButtonClick,
            visible: publishers.value.camera.enabled,
          },
          participants: {
            attrs: {
              icon: {
                green: layout.value == 'cameras',
                users: true,
              },
              label: {
                text: connections.value.length,
                visible: !!connections.value.length,
              },
              text: $gettext('Participants'),
            },
            listener: onParticipantsClick,
          },
          chat: {
            attrs: {
              class: {
                active: state.modals.chat.visible,
                blinking: false,
              },
              label: {
                class: 'red',
                text: unreadMessages.value.length,
                visible: !!unreadMessages.value.length,
              },
              icon: 'comment alternate',
              text: $gettext('Chat'),
            },
            listener: onChatButtonClick,
            visible: !!meeting.value.chatEnabled,
          },
          screen: {
            attrs: {
              icon: {
                green: !publishers.value.screen.active,
                red: publishers.value.screen.active,
                slideshare: true,
              },
              label: {
                class: 'yellow',
                icon: 'exclamation',
                visible: !!publishers.value.screen.error,
              },
              text:
                publishers.value.screen.active
                  ? $gettext('Stop Share')
                  : $gettext('Share Screen'),
            },
            listener: onScreenButtonClick,
            visible: publishers.value.screen.enabled,
          },
          leave: {
            attrs: {
              icon: 'red phone',
              text: $gettext('Leave'),
            },
            listener: onLeaveClick,
          },
          help: {
            attrs: {
              icon: 'green help',
              text: $gettext('Help'),
            },
            listener: () =>
              window.open(
                'https://help.pitcher.com/en/collections/2332491-pitcher-connect-join-troubleshooting',
                '_blank',
              ),
          },
        })),
        header: {
          attrs: {
            style: {
              color: meeting.value.uiFontColor,
            },
          },
          subHeader: computed(() => ({
            attrs: {
              style: {
                color: toSubHeaderColor(meeting.value.uiFontColor),
              },
            },
            text: connections.value.length > 1 ? $gettext('{count} Participants', {count: connections.value.length}) : $gettext('{count} Participant', {count: connections.value.length}),
            visible: !!connections.value.length,
          })),
        },
        logo: meeting.value.uiLogoUrl,
        menu: {
          attrs: {
            class: {
              inverted: !isColorLight(meeting.value.uiColorBack),
              small: computed(() => !mobile.value),
              tiny: computed(() => mobile.value),
            },
          },
        },
        modals: {
          chat: {
            attrs: {
              displayName: props.displayName,
              meeting: props.meetingID,
              participant: computed(() => participantKey.value),
            },
            listeners: {
              message: onChatMessage,
            },
            visible: false,
          },
          exit: {
            visible: false,
          },
        },
        room: {
          attrs: {
            class: computed(() => ({
              [layout.value]: true,
              connected: connected.value,
              mobile: mobile.value,
              portrait: portrait.value,
            })),
          },
        },
        user: {
          active: false,
          activity: {
            timeout: 3000,
            timer: null,
          },
        },
      })

      function onAudioButtonClick() {
        toggleCameraAudio(!publishers.value.camera.audio)
      }

      function onCameraButtonClick() {
        toggleCameraVideo(!publishers.value.camera.video)
      }

      function onChatButtonClick() {
        state.buttons.chat.attrs.class.blinking = false
        state.modals.chat.visible = true
      }

      function onChatMessage() {
        if (!state.modals.chat.visible) {
          state.buttons.chat.attrs.class.blinking = true
        }
      }

      function onChevronClick() {
        if (layout.value == 'fullscreen') {
          layout.value = 'hangout'
        } else {
          layout.value = 'fullscreen'
        }
      }

      function onLeaveClick() {
        state.modals.exit.visible = true
      }

      function onParticipantsClick() {
        if (layout.value == 'cameras') {
          layout.value = 'fullscreen'
        } else {
          layout.value = 'cameras'
        }
      }

      function onScreenButtonClick() {
        try {
          toggleScreen(!publishers.value.screen.active)
        } catch (e) {
          let message

          if (e.name == 'PITCHER_SCREEN_OCCUPIED') {
            message = $gettext('You cannot start screen share while the other participant is sharing.')
          } else {
            message = e.message
          }

          alert(message)
        }
      }

      function onUserActivity() {
        state.user.active = true

        if (state.user.activity.timer) {
          clearTimeout(state.user.activity.timer)
        }

        state.user.activity.timer = setTimeout(
          () => state.user.active = false,
          state.user.activity.timeout,
        )
      }

      function toSubHeaderColor(hex) {
        if (hex) {
          const rgb = convertHexToRgb(hex)
          return `rgba(${rgb[0]},${rgb[1]},${rgb[2]}, 0.5)`
        }
      }

      watch(session, async val => {
        if (val) {
          const res = await createOpentokToken({
            authToken: props.authToken,
            meetingID: props.meetingID,
            sessionID: val,
          })

          await connect(meeting.value.apiKey, val, res.data)
        } else {
          disconnect()
        }
      }, {immediate: true})

      onMounted(async () => {
        connectStatus({
          displayName: props.displayName,
          meetingID: props.meetingID,
        })

        if (props.authToken) {
          await loadRoles(props.authToken)
        }

        const rolePublishers = meetingPublishers.value
          .filter(p => roles.value.includes(p.role))
          .map(p => p.type)

        if (rolePublishers.includes('camera')) {
          if (props.displayName) {
            setCameraName(props.displayName)
          }

          enableCamera(true)
          toggleCamera(true)
        }

        if (rolePublishers.includes('screen')) {
          enableScreen(true)
        }

        addLayoutEventListeners()
        onUserActivity()

        window.addEventListener('mousemove', onUserActivity)
        window.addEventListener('touchstart', onUserActivity)
      })

      onBeforeUnmount(() => {
        removeLayoutEventListeners()

        window.removeEventListener('mousemove', onUserActivity)
        window.removeEventListener('touchstart', onUserActivity)
      })

      return {
        ...toRefs(state),
        connected,
        connections,
        error,
        meeting,
        participantKey,
        layout,
        streamViews,
        toggleScreen,
        onChevronClick,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .background {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .logo {
    left: 50%;
    max-height: 50%;
    max-width: 50%;
    position: fixed;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .stream-views {
    bottom: 65px;
    left: 10px;
    overflow: auto;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .cameras {
    .screen {
      display: none;
    }

    .stream-views {
      display: flex;
    }
  }

  .cameras.portrait {
    .camera {
      margin-bottom: 20px;
      min-height: calc((100vw - 20px) / 16 * 9);
    }

    .stream-views {
      flex-flow: column;
    }
  }

  .cameras:not(.portrait) {
    .camera {
      height: 65vh;
      margin-right: 20px;
      min-width: calc(65vh / 9 * 15);
    }

    .stream-views {
      align-items: center;
    }
  }

  .fullscreen {
    .screen {
      left: 10px;
    }
  }

  .fullscreen.mobile {
    .camera {
      display: none;
    }
  }

  .fullscreen:not(.mobile) {
    .camera {
      transform: translateX(-100%);
    }

    .sidebar-button {
      left: 20px
    }
  }

  .hangout {
    .camera {
      transform: translateX(0);
    }

    .screen {
      left: 260px;
    }

    .sidebar-button {
      left: 270px;
    }
  }

  .fullscreen,
  .hangout {
    .camera {
      height: 135px;
      margin-bottom: 10px;
      position: relative;
      transition: transform .2s ease-out;
      width: 240px;
    }

    .camera:last-child {
      margin-bottom: 0;
    }

    .screen {
      bottom: 65px;
      position: fixed;
      right: 10px;
      top: 10px;
      transition: left .2s ease-out;
    }

    .sidebar-button {
      background: rgba(0, 0, 0, 0.1) none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: left .2s ease-out;
    }
  }

  .ui.header {
    bottom: 15px;
    color: white;
    flex-grow: 1;
    left: 10px;
    margin: 0 10px 0 0;
    position: fixed;

    .sub.header {
      color: rgba(255, 255, 255, .5);
    }
  }

  .connected {
    .ui.header {
      bottom: 10px;
    }
  }

  .ui.menu {
    justify-content: flex-end;

    .item.blinking {
      animation: blink 1.5s infinite;
    }

    .divider {
      background-color: rgba(0, 0, 0, 0.3);
      margin: 10px 15px;
      min-width: 1px;
    }

    .item {
      color: rgba(0, 0, 0, 0.7);

      .ui.label > .exclamation.icon {
        margin: 0;
      }

      .warning-label {
        display: none;
      }

      .menu {
        min-width: 200px;
      }
    }

    .item.dropdown {
      margin-left: 0.357143em !important;
    }

    .item.warning .warning-label {
      display: inline-block;
    }

    .item:last-child {
      margin-right: 10px;
    }
  }

  .ui.menu.inverted .divider {
    background-color: rgba(255, 255, 255, 0.3);
  }

  @keyframes blink {
    50% {
      color: white;
      background-color: #ff392b;
    }
  }

  /* hide buttons based on
     connectivity and screen size */
  @import "@/scss/media.scss";

  .room:not(.connected) {
    .sidebar-button {
      display: none;
    }

    .ui.menu {
      .video-button,
      .audio-button,
      .divider,
      .participants-button,
      .chat-button,
      .screen-button,
      .ui.dropdown.item {
        display: none;
      }
    }
  }

  .room.connected {
    .logo {
      display: none;
    }

    @include md-and-up {
      .ui.menu {
        .participants-button,
        .ui.dropdown.item {
          display: none;
        }
      }
    }

    @include md-and-down {
      .ui.header {
        display: none;
      }

      .ui.menu {
        justify-content: center;
      }
    }

    @include sm-and-up {
      .ui.dropdown.item .menu {
        .help-button {
          display: none;
        }
      }
    }

    @include sm-and-down {
      .ui.menu {
        .chat-button {
          display: none;
        }

        .leave-button {
          display: none;
        }

        .ui.dropdown.item .menu {
          .screen-button {
            display: none;
          }

        }
      }
    }

    @include xs-only {
      .ui.menu {
        .screen-button,
        .help-button,
        .divider {
          display: none;
        }
      }
    }
  }
</style>
