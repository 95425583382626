import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import {reactive, toRefs} from '@vue/composition-api'

const state = reactive({
  initialized: false,
  uid: null,
})

export default () => {
  if (!state.initialized) {
    firebase.auth().onAuthStateChanged(setUser)
    state.initialized = true
  }

  function isAuthenticated() {
    return !!state.uid
  }

  async function setUser(user) {
    if (user) {
      state.uid = user.uid
    } else {
      state.uid = null
    }
  }

  async function signInAnonymously() {
    await firebase.auth().signInAnonymously()
  }

  return {
    ...toRefs(state),
    isAuthenticated,
    setUser,
    signInAnonymously
  }
}
