<template>
  <div>
    <h2 :class="headerColor" class="ui header">
      <i :class="headerIcon" class="icon"></i>
      <div class="content">
        {{headerText}}
        <div class="sub header">
          {{subHeaderText}}
        </div>
      </div>
    </h2>
    <a :class="{loading}" @click="runTests" class="ui huge fluid button">
      {{$gettext('Click here to test your connection')}}
    </a>
  </div>
</template>

<script>
  import useNetworkTest from "../use/network-test";
  import {computed, reactive, toRefs, watchEffect} from "@vue/composition-api";

  export default {
    name: "NetworkTest",
    props: {
      connectivityOnly: {
        default: false,
        type: Boolean
      },
      useConnect: {
        default: false,
        type: Boolean
      }
    },
    setup(props) {
      const {
        audioMos,
        connectivityLoading,
        connectivitySuccess,
        hasAudio,
        hasAudioBandwidth,
        hasVideo,
        hasVideoBandwidth,
        qualityLoading,
        qualityResult,
        testConnectivity,
        testQuality,
        videoMos
      } = useNetworkTest()

      const state = reactive({
        headerColor: computed(() => {
          if (state.loading) {
            return 'blue'
          } else if (connectivitySuccess.value === false || hasAudio.value === false && hasVideo.value === false) {
            return 'red'
          } else if (props.connectivityOnly && connectivitySuccess.value || hasVideoBandwidth.value) {
            return 'olive'
          } else if (hasAudioBandwidth.value) {
            return 'orange'
          }
        }),
        headerIcon: computed(() => {
          if (!state.loading && (connectivitySuccess.value === false || hasAudio.value === false && hasVideo.value === false)) {
            return 'times octagon icon'
          } else if (!state.loading && (props.connectivityOnly && connectivitySuccess.value || hasVideoBandwidth.value)) {
            return 'check circle icon'
          } else if (!state.loading && hasAudioBandwidth.value) {
            return 'exclamation triangle icon'
          } else {
            return 'wifi'
          }
        }),
        headerText: computed(() => {
          if (!state.loading && connectivitySuccess.value === false) {
            return $gettext('No Connectivity')
          } else if (!state.loading && props.connectivityOnly && connectivitySuccess.value) {
            return $gettext('Good Connectivity')
          } else if (!state.loading && hasVideo.value) {
            return mosText(videoMos.value)
          } else if (!state.loading && hasAudio.value) {
            return mosText(audioMos.value)
          } else if (!state.loading && hasAudio.value === false && hasVideo.value === false) {
            return $gettext('Device Issue')
          } else {
            return $gettext('Connectivity Test')
          }
        }),
        loading: computed(() => connectivityLoading.value || qualityLoading.value),
        subHeaderText: computed(() => {
          if (connectivityLoading.value) {
            return $gettext('Testing connection...')
          } else if (connectivitySuccess.value === false) {
            return $gettext('Unable to connect to our media network. Please check your internet connection and try again.')
          } else if (qualityLoading.value) {
            return $gettext('Testing audio / video quality...')
          } else if (props.connectivityOnly && connectivitySuccess.value || hasVideoBandwidth.value) {
            return $gettext("You're all set!")
          } else if (!props.connectivityOnly && hasVideo.value && hasVideoBandwidth.value === false && hasAudioBandwidth.value) {
            return $gettext('Your bandwidth can support audio only.')
          } else if (!props.connectivityOnly && hasVideo.value === false && hasAudio.value && hasAudioBandwidth.value) {
            return $gettext("You can't send video because no camera was found, but your bandwidth can support an audio-only stream.")
          } else if (!props.connectivityOnly && hasVideo.value === false && hasAudio.value && hasAudioBandwidth.value === false) {
            return $gettext("You can't send video because no camera was found, and your bandwidth is too low for an audio-only stream.")
          } else if (!props.connectivityOnly && hasAudio.value === false) {
            return $gettext("No supported devices found or your browser doesn't support WebRTC. Please make sure you allow access to your devices when asked. We recommend the Google Chrome on Desktop and Android, Safari on iOS/iPadOS for best overall performance.")
          } else {
            return $gettext('Test connectivity, media access, and quality.')
          }
        })
      })

      function mosText(mos) {
        if (mos >= 3.8) {
          return $gettext('Excellent Connectivity')
        } else if (mos >= 3.1) {
          return $gettext('Good Connectivity')
        } else if (mos >= 2.4) {
          return $gettext('Fair Connectivity')
        } else if (mos >= 1.7) {
          return $gettext('Poor Connectivity')
        } else if (mos) {
          return $gettext('Bad Connectivity')
        }
      }

      async function runTests() {
        if (props.useConnect) {
          return window.open('https://connect.pitcher.com/live_support/test/', '_blank')
        }

        await testConnectivity()

        if (!props.connectivityOnly && connectivitySuccess.value) {
          await testQuality()
        }
      }

      return {
        ...toRefs(state),
        runTests,
        qualityResult
      }
    }
  }
</script>

<style scoped>

</style>