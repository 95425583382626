<template>
  <SuiModal basic class="chat-modal" close-icon :open="open" @changed="$emit('changed', false)">
    <iframe :src="iframeSrc" ref="iframe" v-if="opened"/>
  </SuiModal>
</template>

<script>
  import Vue from 'vue'
  import {computed, onBeforeUnmount, onMounted, reactive, toRefs, watch} from '@vue/composition-api'
  import {useChat} from '../use/chat'

  export default {
    name: 'ChatModal',
    model: {
      prop: 'open',
      event: 'changed',
    },
    props: {
      displayName: {
        default: 'Anonymous User',
        type: String,
      },
      meeting: {
        type: String,
      },
      open: {
        type: Boolean,
      },
      participant: {
        type: String,
      },
    },
    setup(props) {
      const {
        addMessage,
        makAllAsRead
      } = useChat()

      const state = reactive({
        iframe: null,
        iframeOffsetHeight: 400,
        iframeOffsetWidth: 400,
        iframeSrc: computed(getIframeSrc),
        opened: false,
      })

      function getIframeSrc() {
        const url = new URL('https://chat.pitcher.com/chat/chat.php')
        url.searchParams.append('chatViewWidth', state.iframeOffsetWidth - 4)
        url.searchParams.append('chatViewHeight', state.iframeOffsetHeight - 4)
        url.searchParams.append('joinName', props.displayName)
        url.searchParams.append('joinUserID', props.participant)
        url.searchParams.append('meetingID', props.meeting)
        url.searchParams.append('noCam', 'true')
        url.searchParams.append('userTypeInformation', 'customer')
        return url.href
      }

      function onMessageEvent(e) {
        let message

        try {
          message = JSON.parse(e.data)
        } catch (e) {
          return
        }

        if (message.command == 'fromChat' && message.params.type == 'newMessage') {
          addMessage(message.message, props.open)
        }
      }

      function onResize() {
        if (state.iframe) {
          state.iframeOffsetWidth = state.iframe.offsetWidth
          state.iframeOffsetHeight = state.iframe.offsetHeight
        }
      }

      onMounted(() => {
        window.addEventListener('message', onMessageEvent, false)
      })

      onBeforeUnmount(() => {
        window.removeEventListener('message', onMessageEvent)
      })

      watch(() => props.open, val => {
        if (val) {
          makAllAsRead()
          state.opened = true
          Vue.nextTick(onResize)
        }
      })

      return toRefs(state)
    },
  }
</script>

<style lang="scss">
  .chat-modal {
    i.close.icon {
      color: rgba(0, 0, 0, 0.85) !important;
    }

    iframe {
      width: 100%;
      min-height: 50vh;
    }
  }

  @media only screen and (max-height: 767px) {
    .chat-modal iframe {
      min-height: calc(100vh - 2em);
    }
  }
</style>
