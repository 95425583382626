import * as Sentry from '@sentry/browser'
import NetworkTest, {ErrorNames} from 'opentok-network-test-js'
import useFunctions from './functions'
import {computed, reactive, toRefs} from '@vue/composition-api'

const bandwidthThreshold = 2.4
const ignoreConnectivityTests = [
  ErrorNames.FAILED_TO_CREATE_LOCAL_PUBLISHER,
  ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES,
  ErrorNames.NO_AUDIO_CAPTURE_DEVICES,
  ErrorNames.NO_VIDEO_CAPTURE_DEVICES
]
const networkTestTimeout = 10000

const state = reactive({
  audioMos: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.audio.mos
    }
  }),
  connectivityLoading: false,
  connectivityResult: null,
  connectivitySuccess: computed(() => {
    if (state.connectivityResult) {
      return state.connectivityResult.success || !state.connectivityResult.failedTests.filter(t => !ignoreConnectivityTests.includes(t.error.name)).length
    }
  }),
  hasAudio: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.audio.supported
    }
  }),
  hasAudioBandwidth: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.audio.mos > bandwidthThreshold
    }
  }),
  hasVideo: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.video.supported ||  state.qualityResult.video.reason == 'Bandwidth too low.'
    }
  }),
  hasVideoBandwidth: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.video.mos > bandwidthThreshold
    }
  }),
  qualityLoading: false,
  qualityResult: null,
  supportsAudio: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.audio.supported
    }
  }),
  supportsVideo: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.video.supported
    }
  }),
  videoMos: computed(() => {
    if (state.qualityResult) {
      return state.qualityResult.video.mos
    }
  })
})

export default () => {
  const {
    createOpentokNetworkTestCredentials
  } = useFunctions()

  let credentials, credentialsTimeCreated;

  async function testConnectivity() {
    state.connectivityLoading = true
    state.connectivityResult = null

    try {
      await updateCredentials();

      let networkTest = new NetworkTest(OT, credentials, {timeout: networkTestTimeout})
      state.connectivityResult = await networkTest.testConnectivity()
    } catch (e) {
      Sentry.captureException(e)
      state.connectivityResult = {failedTests: [{error: {...e, name: 'FatalError'}, type: 'Fatal'}], success: false}
    }

    state.connectivityLoading = false
  }

  async function testQuality() {
    state.qualityError = null
    state.qualityLoading = true
    state.qualityResult = null

    try {
      await updateCredentials();

      try {
        const networkTest = new NetworkTest(OT, credentials, {timeout: networkTestTimeout})
        state.qualityResult = await networkTest.testQuality()
      } catch (e) {
        const networkTest = new NetworkTest(OT, credentials, {audioOnly: true, timeout: networkTestTimeout})
        state.qualityResult = await networkTest.testQuality()
      }
    } catch (e) {
      Sentry.captureException(e)

      state.qualityResult = {
        audio: {mos: 1, supported: false},
        failedTest: [{error: e}],
        success: false,
        video: {mos: 1, supported: false}
      }
    }

    state.qualityLoading = false
  }

  async function updateCredentials() {
    if (!credentials || credentialsTimeCreated < new Date().getTime() - 600) {
      const res = await createOpentokNetworkTestCredentials()
      credentials = res.data
      credentialsTimeCreated = new Date().getTime()
    }
  }

  return {
    ...toRefs(state),
    testConnectivity,
    testQuality
  }
}