import Confrence from './views/Confrence'
import Exit from './views/Exit'
import Precall from './views/Precall'
import Splashscreen from './views/Splashscreen'
import useAuth from "./use/auth";
import Vue from 'vue'
import VueRouter from 'vue-router'
import Code from "./views/Code";

Vue.use(VueRouter)

const routes = [
  {
    component: Code,
    name: 'code',
    path: '/code'
  },
  {
    component: Confrence,
    name: 'confrence',
    path: '/confrence',
    props: route => ({
      authToken: route.query.authToken,
      displayName: route.query.displayName,
      meetingID: route.query.meetingID
    })
  },
  {
    component: Precall,
    name: 'precall',
    path: '/precall',
    props: route => ({
      authToken: route.query.authToken,
      displayName: route.query.displayName,
      meetingID: route.query.meetingID
    })
  },
  {
    component: Exit,
    name: 'exit',
    path: '/exit'
  },
  {
    component: Splashscreen,
    name: 'splashscreen',
    path: '/:meetingID?',
    props: route => ({
      authToken: route.query.authToken,
      displayName: route.query.displayName,
      meetingID: route.params.meetingID
    })
  }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE || 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach((to, from, next) => {
  const {
    isAuthenticated
  } = useAuth()

  if (to.name !== 'splashscreen' && !isAuthenticated()) {
    next({
      name: 'splashscreen',
      params: {meetingID: to.query.meetingID},
      query: {
        authToken: to.query.authToken,
        displayName: to.query.displayName,
      }
    })
  } else {
    next()
  }
})

export default router
