<template>
  <BasicModal :open="modal.visible" v-bind="modal.attrs"/>
</template>

<script>
  import {reactive, toRefs, watch} from '@vue/composition-api'
  import BasicModal from './BasicModal'

  export default {
    name: 'OTErrorModal',
    components: {BasicModal},
    props: {
      error: {
        type: Object,
      },
    },
    setup(props) {
      // https://static.opentok.com/developer/sdks/js/reference/Error.html
      const errorAttrs = {
        OT_CHROME_MICROPHONE_ACQUISITION_ERROR: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('You have not authorized access to your camera and/or microphone.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Media Error'),
          },
        },
        OT_CONNECT_FAILED: {
          actions: [
            {
              listeners: {
                click: reload,
              },
              text: $gettext('Try Again'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Your streaming connection has failed. Please check your firewall or VPN restrictions.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'red exclamation circle',
              },
            },
            text: $gettext('Network Error'),
          },
        },
        CONNECT_FAILED: {
          actions: [
            {
              listeners: {
                click: reload,
              },
              text: $gettext('Try Again'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Your streaming connection has failed. Please check your firewall or VPN restrictions.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'red exclamation circle',
              },
            },
            text: $gettext('Network Error'),
          },
        },
        OT_CREATE_PEER_CONNECTION_FAILED: {
          actions: [
            {
              listeners: {
                click: reload,
              },
              text: $gettext('Try Again'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Your streaming connection has failed. Please check your firewall or VPN restrictions.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'red exclamation circle',
              },
            },
            text: $gettext('Network Error'),
          },
        },
        OT_HARDWARE_UNAVAILABLE: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application or another tab.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Hardware Error'),
          },
        },
        OT_ICE_WORKFLOW_FAILED: {
          actions: [
            {
              listeners: {
                click: reload,
              },
              text: $gettext('Try Again'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Streaming connection failed. This could be due to a restrictive firewall or VPN.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'red exclamation circle',
              },
            },
            text: $gettext('Network Error'),
          },
        },
        OT_MEDIA_ERR_ABORTED: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application or another tab.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Media Error'),
          },
        },
        OT_NO_DEVICES_FOUND: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('No voice or video input devices are available on this machine. Please use another device enabling audio or video.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Media Error'),
          },
        },
        OT_NOT_CONNECTED: {
          actions: [
            {
              listeners: {
                click: reload,
              },
              text: $gettext('Try Again'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('You are not connected to the internet. Please check your internet connection.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'red exclamation circle',
              },
            },
            text: $gettext('Network Error'),
          },
        },
        OT_NOT_SUPPORTED: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Your browser doesn\'t support audio/video communication. Please use the latest version of Google Chrome (for Windows/Android) or Safari (for Apple devices).'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Media Error'),
          },
        },
        OT_UNSUPPORTED_BROWSER: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Your browser doesn\'t support audio/video communication. Please use the latest version of Google Chrome (for Windows/Android) or Safari (for Apple devices).'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Media Error'),
          },
        },
        OT_PERMISSION_DENIED: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('You have not authorized access to your camera and/or microphone.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Media Error'),
          },
        },
        OT_TIMEOUT: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
          },
          content: {
            text: $gettext('Your streaming connection has failed. Please check your firewall or VPN restrictions.'),
          },
          header: {
            icon: {
              attrs: {
                class: 'yellow exclamation triangle',
              },
            },
            text: $gettext('Network Error'),
          },
        },
        PITCHER_SCREEN_OCCUPIED: {
          actions: [
            {
              listeners: {
                click: close,
              },
              text: $gettext('OK'),
            },
          ],
          attrs: {
            basic: true,
            size: 'small'
          },
          header: {
            attrs: {
              class: 'no-border'
            },
            icon: {
              attrs: {
                class: 'blue info circle'
              }
            },
            text: $gettext('You cannot start screen share while the other participant is sharing.'),
          },
        },
      }

      const state = reactive({
        modal: {
          attrs: {},
          visible: false
        }
      })

      function close() {
        state.modal.visible = false
      }

      function reload() {
        window.location.reload()
      }

      watch(() => props.error, val => {
        if (val) {
          const attrs = errorAttrs[val.name]

          if (attrs) {
            state.modal.attrs = attrs
            state.modal.visible = true
          }
        }
      })

      return {
        ...toRefs(state)
      }
    },
  }
</script>

<style scoped>
  .header.no-border {
    border-bottom: 0;
  }
</style>
